@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border;
  }

  body {
    @apply font-sans antialiased;
    -webkit-tap-highlight-color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-clip: text;
    -webkit-text-fill-color: inherit;
    box-shadow: inset 0 0 20px 20px #fff;
  }
}

/* SwiperJs styles */
.swiper-wrapper {
  display: flex;
}
.swiper-pagination {
  position: relative;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1.5rem;
  justify-content: center;
}
.swiper-pagination-bullet {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #dfe9f6;
  border-radius: 6.25rem;
  transition: all 0.3s ease;
}
.swiper-pagination-bullet-active {
  width: 1rem;
  background-color: #4e68f6;
}
